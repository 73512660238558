/**
 ** Copyright (C) 2017 Digital Cognition Technologies.  All Rights Reserved.
 ** Unauthorized copying of this file via any medium is strictly prohibited
 ** without the express permission of Digital Cognition Technologies.
 ** Proprietary and confidential.
 **/
/*##############################################################################
Player timebar
##############################################################################*/
.font_xxxs {
  font-size: 10px;
  line-height: 12px;
}
.font_xs {
  font-size: 14px;
  line-height: 16px;
}
.disabled_color {
  color: #9e9e9e;
}
.primary_color {
  color: #087dae;
}
.font_xxs {
  font-size: 12px;
  line-height: 16px;
}
.font_monospace_numbers {
  /* When displaying rapidly-changing times, a number with monospace numbers is essential!
  These all work, but Poppins does not! */
  font-family: 'Noto Sans', 'Helvetica', 'Arial', sans-serif;
}
.font_icon {
  font-family: 'dct-font-awesome', sans-serif;
  font-weight: normal !important;
  font-style: normal !important;
  user-select: none;
}
/*##############################################################################
Bar itself
##############################################################################*/
/* The complete timebar */
.timebar {
  margin: 0px -30px 4px -30px;
  /* Expand the track to help with overflow */
  padding: 0px 30px 4px 30px;
  /* Expand the track to help with overflow */
  overflow: hidden;
  position: relative;
}
/*##############################################################################
Labels
##############################################################################*/
/* The time labels on the left and right */
.timebar_label {
  /* When displaying rapidly-changing times, a number with monospace numbers is essential!
  These all work, but Poppins does not! */
  font-family: 'Noto Sans', 'Helvetica', 'Arial', sans-serif;
  font-size: 12px;
  line-height: 12px;
  height: 28px;
  position: absolute;
  top: 0px;
  transition: opacity 128ms linear 0ms;
  opacity: 1;
}
.timebar_playmode .timebar_label {
  opacity: 0;
  /* Disappears when playback is active */
}
.timebar_start_time {
  left: 30px;
}
.timebar_end_time {
  right: 30px;
}
/*##############################################################################
Track and bar
##############################################################################*/
.timebar_track {
  margin: 0px -30px 0px -30px;
  padding: 0px 30px 0px 30px;
  height: 28px;
  position: relative;
}
.timebar_clickcatcher {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  height: 38px;
  z-index: 9;
}
/*##############################################################################
Segments
##############################################################################*/
.timebar_segments {
  display: flex;
  position: relative;
  height: 30px;
}
.dct_player_timebar_split .timebar_segments {
  padding-bottom: 20px;
}
.timebar_segment {
  position: absolute;
  display: inline-block;
  box-sizing: border-box;
  text-align: center;
  color: #4d4d4d;
  cursor: pointer;
}
.timebar_segment_clickable:hover .timebar_segment_detail .timebar_segment_detail {
  background-color: rgba(0, 0, 0, 0.02);
}
/* White-out sections of the time bar where the pen is NOT down. */
.timebar_segment_off:before {
  display: block;
  position: absolute;
  top: 0px;
  content: ' ';
  width: 100%;
  box-sizing: border-box;
  border-width: 4px 0px 0px 0px;
  border-style: solid;
  border-color: #ffffff;
  transition: border-color 128ms linear 0ms;
  z-index: 2;
}
.timebar_segment_detail {
  /* Top border */
  border-top: 6px solid #c2c2c2;
  /* This negative margin allows icons to overlap - and that's okay, we want that! */
  overflow: visible;
  height: 20px;
  pointer-events: none;
  /* Split mode */
}
.timebar_segment_detail > .timebar_segment_thumb {
  opacity: 0.38;
  transition: opacity 100ms linear 0ms;
}
.timebar_segment.active .timebar_segment_detail > * {
  opacity: 0.87;
}
.dct_player_timebar_split .timebar_segment_detail {
  padding-bottom: 20px;
}
.timebar_segment_thumb {
  margin: 0px -20px;
  height: 20px;
}
.timebar_segment_icon {
  margin: 0px -20px 0px -20px;
  height: 20px;
  font-size: 14px;
}
.timebar_segment_scissor {
  pointer-events: none;
  margin: 0px -16px;
  /* Allows text centering when pause is narrow... */
  transition: color 128ms linear 0ms, opacity 128ms linear 0ms;
  color: #e0e0e0;
}
.timebar_segment_scissor:before {
  content: '\f504';
  font-family: 'dct-font-awesome', sans-serif;
  font-weight: normal !important;
  font-style: normal !important;
  user-select: none;
  z-index: 3;
  display: inline-block;
}
.timebar_segment:hover .timebar_segment_scissor {
  color: #86c65a;
}
.timebar_segment_cutpoint .timebar_segment_scissor {
  color: #087dae !important;
}
.timebar_segment_cutpoint .timebar_segment_scissor:after {
  content: ' ';
  height: 52px;
  width: 0px;
  position: absolute;
  top: -20px;
  left: 50%;
  z-index: 4;
  border-left: 1px dotted #86c65a;
}
/*##############################################################################
Moving arrow
##############################################################################*/
.timebar_arrow,
.timebar_ghost_arrow {
  transform: translate3d(0%, 0, 0);
}
.timebar_arrow {
  transition: opacity 128ms linear 0ms;
}
.timebar_arrow:before,
.timebar_ghost_arrow:before {
  position: absolute;
  display: inline-block;
  /* This data url is an SVG of a tiny pen. */
  /* Note, this is in our icon font too, but the anti-aliasing of fonts makes it look ugly, so going data url here. */
  content: “▼”;
  margin-top: 5px;
  vertical-align: top;
  transition: transform 64ms linear 0ms;
  transform: translate3d(0px, 0px, 0px);
}
.timebar_arrow_down:before {
  transform: translate3d(0px, 6px, 0px);
}
.timebar_ghost_arrow:before {
  opacity: 0;
}
.timebar_ghost_arrow.active:before {
  opacity: 0.12;
}
.timebar_time {
  display: inline-block;
  width: 60px;
  margin-left: -54px;
  height: 12px;
  color: #4d4d4d;
  font-size: 12px;
  line-height: 12px;
  position: absolute;
  text-align: right;
  cursor: default;
  user-select: none;
  /* When displaying rapidly-changing times, a number with monospace numbers is essential!
  These all work, but Poppins does not! */
  font-family: 'Noto Sans', 'Helvetica', 'Arial', sans-serif;
}
/* The progress bar */
.timebar_bar {
  position: absolute;
  top: 28px;
  border-style: solid;
  border-width: 6px 0px 0px 0px;
  border-color: #087dae;
  width: calc(100% - (2 * 30px));
  transform: scale(0, 1);
  transform-origin: left top;
  z-index: 1;
}
/*##############################################################################
Thumbnails
##############################################################################*/
.timebar_thumb {
  width: 16px;
  height: 16px;
}
.controlbar {
  display: flex;
}
@media print {
  .controlbar {
    display: none;
  }
}
.controlbar_spacer {
  flex: 1;
}
.controlbar_spallspacer {
  width: 16px;
}
.controlbar_link {
  display: inline-block;
  text-align: center;
  vertical-align: top;
  font-size: 18px;
  color: #4d4d4d;
  width: 32px;
  height: 32px;
  line-height: 32px;
  border-radius: 32px;
  border: none;
  transition: color 100ms linear 0ms, background-color 100ms linear 0ms;
}
.controlbar_link:hover {
  color: #191919;
}
.controlbar_link_speed {
  font-size: 14px;
}
.controlbar_link_speed,
.controlbar_link_annot {
  color: #9e9e9e;
}
.controlbar_link.active {
  color: #4d4d4d;
  background-color: rgba(0, 0, 0, 0.02);
}
.controlbar_link_disabled {
  pointer-events: none;
  color: #c2c2c2 !important;
}
/*##############################################################################
Scissors
##############################################################################*/
.controlbar_scissor {
  line-height: 32px;
  height: 32px;
  display: inline-block;
  color: #9e9e9e;
}
/*##############################################################################
Micro controlbar, when we don't have the room for a full one
##############################################################################*/
.microcontrolbar {
  display: inline-block;
  position: absolute;
  left: 4px;
  bottom: 4px;
  white-space: nowrap;
  font-size: 14px;
  line-height: 24px;
}
.microcontrolbar_play {
  display: inline-block;
  width: 24px;
  height: 24px;
  line-height: 24px;
  text-align: center;
  color: #087dae;
}
.microtitle {
  display: block;
  position: absolute;
  top: 4px;
  left: 4px;
  white-space: nowrap;
  font-size: 10px;
  line-height: 12px;
  color: #9e9e9e;
}
.controlbar_playbutton {
  transform: translate(1px, 1px);
}
